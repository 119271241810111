import './connect-page.styles.scss'

const ConnectPage = () => {
    
    return (
      <div className="connect-page">
        <h2>Join the Discord server to get updates on the site and give feedback on the idea!</h2>
        
        <form action="https://discord.gg/A8xEjAXT" method="get" target="_blank" rel="noreferrer">
          <button className="DiscordButton"type="submit">Discord Invite</button>
        </form>
        <p>Alternativly, Discord server invite link here: <a href="https://discord.gg/A8xEjAXT" target="_blank" rel="noreferrer">https://discord.gg/A8xEjAXT</a>.</p>
      </div>
        
    );
    
  };
  
export default ConnectPage;