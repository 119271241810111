//Navigation

import { Outlet, Link } from 'react-router-dom'

import { Fragment} from 'react' //component that renders to nothing if mounted on DOM

import './navigation.styles.scss'


const Navigation = () => {
	
	return(
		<Fragment>
			<div className='navigation'> 
				<div className='nav-links-container'>
					<Link className='nav-link' to='/'>
						<div>Pitch</div>
					</Link>
					<Link className='nav-link' to='/connect'>
						<div>Get more updates</div>
					</Link>
				</div>
			</div> 
			<Outlet />
		</Fragment>
	);
};

export default Navigation;
