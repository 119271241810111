import './pitch-page.styles.scss'

const PitchPage = () => {
    
    return (
        <div className="pitch-page">
            <h2 className='power-hook'>
            Get good feedback. Get paid well for good feedback.
            </h2>
            <h2 className='topic-section'>Target Audience</h2>
            <p className='topic-paragraph'>
            Idea creators such as future entrepreneurs, current entrepreneurs, open-source project creators, and content creators.
            </p>
            <p className='topic-paragraph'>
            Feedback givers such as paid survey takers, customers, and anyone with exceptional insight and familiarty within a field(music, engineering, toys, etc.).
            </p>
            <h2 className='topic-section'>Problems to Solve</h2>
            <h3>For the idea creator</h3>
            <p className='topic-paragraph'>
            It takes too much time to get good quality feedback online on an idea. 
            Usually get rejected, labelled as spam, ignored, or trolled.
            </p>
            <p className='topic-paragraph'>
            One bad business decision can ruin people's trust and perception of your business. 
            </p>
            <h3>For the feedback giver</h3>
            <p className='topic-paragraph'>
            Current survey sites pay you very little money for taking a long boring survey.
            </p>
            <p className='topic-paragraph'>
            Current survey sites treat you like a number from a demographic and don't give you much of a chance to earn more for your individual valuable insight.
            </p>
            <h2 className='topic-section'>Solution</h2>
            <p className='topic-paragraph'>
            <b>A centralized site for feedback where people are incentivized to give good feedback and are rewarded well for it. </b> 
            </p>
            <p className='topic-paragraph'>
            For idea creators, you can test an idea out, get good quality feedback on it from various people, and
            rate how useful you found the feedback. Improve the idea, sell the idea, and repeat until product development.
            </p>
            <p className='topic-paragraph'>
            For feedback givers, you can be compensated well for giving great quality feedback as well as do something important like make a difference in others lives.
            </p>
            <h2 className='topic-section'>Features</h2>
            <p className='topic-paragraph'>
                <li>Conveniently post an idea and get good feedback based on a high quality feedback template.</li> 
                <li>Earn more money for your opinion by providing higher quality unique feedback.</li>
                <li>Special mass feedback request from a target audience, paid feature.</li>
                <li>Sponsored top section of a post for a category, paid feature.</li>
            </p>
            
            <h2 className='topic-section'>Vision</h2>
            
            <p className='topic-paragraph'>
            To help people <b>create more opportunities for others</b> so that we may all <b>use our unique talents, gifts, and insights to serve each other</b>.
            </p>
            <p className='topic-paragraph'>
            <b>Create more opportunity in the world</b> rather than compete.
            </p>


        </div>
        
    );
    
  };
  
  export default PitchPage;
  