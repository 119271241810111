import './App.css';

import { Routes, Route } from 'react-router-dom'

import Navigation from './routes/navigation/navigation.component';
import PitchPage from './routes/pitch-page/pitch-page.jsx';
import ConnectPage from './routes/connect-page/connect-page.jsx'

/*
The website should have a navigation bar. 
Pages should be pitch, and submission form to sign up to hear more.

*/

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Navigation/>} >
          <Route path='/' element={<PitchPage/>} />
          <Route path='connect' element={<ConnectPage/>} />
        </Route>			
      </Routes>
    </div>
  );
}

export default App;
